import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Flex } from "@chakra-ui/react";
import SectionHeading from "./SectionHeading";
import SectionRow from "./SectionRow";
import ResponsiveColumn from "./ResponsiveColumn";
import SpotlightLarge from "./SpotlightLarge";
import ArticleBlock from "./ArticleBlock";

export default function Spotlight() {
  return (
    <StaticQuery
      query={graphql`
        query SpotlightQuery {
          allAirtable(
            filter: { table: { eq: "snapshots" } }
            sort: { fields: data___date_created, order: DESC }
            limit: 1
          ) {
            edges {
              node {
                id
                data {
                  snapshot
                  date_created
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const snapshot = JSON.parse(
          data.allAirtable.edges[0].node.data.snapshot
        );
        const featured = snapshot.featured;
        return (
          <Flex id="featured" direction="column" mb="6">
            <SectionHeading heading="featured" />
            <SectionRow>
              <ResponsiveColumn flex="100%">
                <SpotlightLarge
                  image_description={featured[0].image_description}
                  headline={featured[0].headline}
                  link={featured[0].link}
                  source={featured[0].source}
                  category={featured[0].category}
                  id={featured[0].id}
                ></SpotlightLarge>
              </ResponsiveColumn>
            </SectionRow>
            <SectionRow>
              <ResponsiveColumn
                flex={{ base: "100%", sm: "50%", md: "33%", lg: "33%" }}
              >
                <ArticleBlock
                  image_description={featured[1].image_description}
                  headline={featured[1].headline}
                  link={featured[1].link}
                  source={featured[1].source}
                  category={featured[1].category}
                  id={featured[1].id}
                ></ArticleBlock>
              </ResponsiveColumn>
              <ResponsiveColumn
                flex={{ base: "100%", sm: "50%", md: "33%", lg: "33%" }}
              >
                <ArticleBlock
                  image_description={featured[2].image_description}
                  headline={featured[2].headline}
                  link={featured[2].link}
                  source={featured[2].source}
                  category={featured[2].category}
                  id={featured[2].id}
                ></ArticleBlock>
              </ResponsiveColumn>
              <ResponsiveColumn
                flex={{ base: "100%", sm: "100%", md: "33%", lg: "33%" }}
              >
                <ArticleBlock
                  image_description={featured[3].image_description}
                  headline={featured[3].headline}
                  link={featured[3].link}
                  source={featured[3].source}
                  category={featured[3].category}
                  id={featured[3].id}
                ></ArticleBlock>
              </ResponsiveColumn>
              {/* <ResponsiveColumn
                flex={{ base: "0", sm: "50%", md: "0%", lg: "0" }}
              > */}
              {/* leave this empty for even spacing with other rows spacing */}
              {/* </ResponsiveColumn> */}
            </SectionRow>
          </Flex>
        );
      }}
    />
  );
}

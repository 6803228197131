import React from "react";

import { Flex } from "@chakra-ui/react";

export default function SectionRow({ id, children }) {
  return (
    <Flex
      id={id}
      h="100%"
      flexWrap="wrap"
      w="100%"
      maxW="1250px"
      justify="space-between"
      mb="2"
      mx="auto"
      align="center"
    >
      {children}
    </Flex>
  );
}

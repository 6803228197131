import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Flex } from "@chakra-ui/react";
import SectionHeading from "./SectionHeading";
import SectionRow from "./SectionRow";
import ResponsiveColumn from "./ResponsiveColumn";
import ArticleBlock from "./ArticleBlock";
import ArchivesLink from "./ArchivesLink";

export default function Perspectives() {
  return (
    <StaticQuery
      query={graphql`
        query PerspectivesQuery {
          allAirtable(
            filter: { table: { eq: "snapshots" } }
            sort: { fields: data___date_created, order: DESC }
            limit: 1
          ) {
            edges {
              node {
                id
                data {
                  snapshot
                  date_created
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const snapshot = JSON.parse(
          data.allAirtable.edges[0].node.data.snapshot
        );
        const perspectives = snapshot.perspectives;
        return (
          <Flex id="perspectives" direction="column" mb="6">
            <SectionHeading heading="personal perspectives" />
            <SectionRow id="perspectives">
              <ResponsiveColumn
                flex={{ base: "100%", sm: "100%", md: "50%", lg: "50%" }}
              >
                <ArticleBlock
                  image_description={perspectives[0].image_description}
                  headline={perspectives[0].headline}
                  link={perspectives[0].link}
                  source={perspectives[0].source}
                  category={perspectives[0].category}
                  id={perspectives[0].id}
                ></ArticleBlock>
              </ResponsiveColumn>
              <ResponsiveColumn
                flex={{ base: "100%", sm: "100%", md: "50%", lg: "50%" }}
              >
                <ArticleBlock
                  image_description={perspectives[1].image_description}
                  headline={perspectives[1].headline}
                  link={perspectives[1].link}
                  source={perspectives[1].source}
                  category={perspectives[1].category}
                  id={perspectives[1].id}
                ></ArticleBlock>
              </ResponsiveColumn>
            </SectionRow>
            <ArchivesLink text="see more PERSPECTIVES" url="/perspectives" />
          </Flex>
        );
      }}
    />
  );
}

import React from "react";

import { Flex } from "@chakra-ui/react";

export default function ResponsiveColumn({ children, flex, id, bgColor }) {
  return (
    <Flex
      id={id ? id : null}
      bg={"transparent"}
      w="100%"
      p="1"
      // minW={{ base: "260px", sm: "180px", md: "190px", lg: "170px" }}
      direction="column"
      align="center"
      // flex={flex}
      flexBasis={flex}
    >
      {children}
    </Flex>
  );
}

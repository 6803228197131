import React from "react";
import GatsbyLink from "gatsby-link";
import { Flex, Text, Image } from "@chakra-ui/react";
import slugify from "slugify";

export default function BookBlock({
  image,
  image_description,
  headline,
  link,
  source,
  category,
  id,
}) {
  // const img = getImage(image);
  const slug = slugify(headline, {
    lower: true,
    strict: true,
  });
  const url = `/${category}/${slug}`;
  const local_image_path = `/images/${category}_${id}.jpg`;

  return (
    <Flex
      m="0 auto"
      w="100%"
      direction="column"
      fontFamily="Montserrat"
      as={GatsbyLink}
      to={url}
      target="_blank"
      color="purple"
      py="2"
    >
      <Flex h={{ base: "300px", sm: "350px", md: "400px", lg: "500px" }}>
        <Image
          objectFit="contain"
          src={local_image_path}
          alt={image_description}
        />
      </Flex>
      <Flex direction="column" justify="space-evenly" mt="1">
        <Flex direction="column" py="1">
          <Text fontSize={{ base: "20px" }} fontWeight="700" color="purple">
            {headline}
          </Text>
          <Text fontSize={{ base: "14px" }} fontWeight="600" color="purple">
            by {source}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

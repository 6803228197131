import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Flex, Link } from "@chakra-ui/react";
import SectionHeading from "./SectionHeading";
import SectionRow from "./SectionRow";
import AdjustibleResponsiveColumn from "./AdjustableResponsiveColumn";
import BookBlock from "./BookBlock";
import PodcastBlock from "./PodcastBlock";
// import ArchivesLink from "./ArchivesLink";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export default function AlzAuthors() {
  return (
    <StaticQuery
      query={graphql`
        query AlzAuthorsBooksQuery {
          allAirtable(
            filter: { table: { eq: "snapshots" } }
            sort: { fields: data___date_created, order: DESC }
            limit: 1
          ) {
            edges {
              node {
                id
                data {
                  snapshot
                  date_created
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const snapshot = JSON.parse(
          data.allAirtable.edges[0].node.data.snapshot
        );
        const books = snapshot.books;
        const podcasts = snapshot.podcasts;
        return (
          <Flex
            id="books"
            direction="column"
            mb="6"
            border="solid"
            borderColor="purple"
            borderWidth="8px"
            p="3"
          >
            <Flex direction="column">
              <Flex
                mr={{ base: "10px", sm: "5px", md: "0" }}
                as={Link}
                href="https://alzauthors.com"
                target="_blank"
                py="4"
                px="2"
                w={{
                  base: "180px",
                  sm: "220px",
                  md: "260px",
                  lg: "300px",
                  xl: "340x",
                }}
              >
                <StaticImage
                  src={"../images/alzauthorpurplebook.png"}
                  alt="Alz Authors"
                  placeholder="none"
                />
              </Flex>
            </Flex>
            {/* alz authors books row */}
            <SectionHeading heading="books" bg="purple" />
            <SectionRow id="books">
              <AdjustibleResponsiveColumn
                flex={{ base: "100%", sm: "50%", md: "33%", lg: "33%" }}
                height={{
                  base: "400px",
                  sm: "550px",
                  md: "550px",
                  lg: "620px",
                }}
              >
                <BookBlock
                  image_description={books[0].image_description}
                  headline={books[0].headline}
                  link={books[0].link}
                  source={books[0].source}
                  category={books[0].category}
                  id={books[0].id}
                ></BookBlock>
              </AdjustibleResponsiveColumn>
              <AdjustibleResponsiveColumn
                flex={{ base: "100%", sm: "50%", md: "33%", lg: "33%" }}
                height={{
                  base: "400px",
                  sm: "550px",
                  md: "550px",
                  lg: "620px",
                }}
              >
                <BookBlock
                  image_description={books[1].image_description}
                  headline={books[1].headline}
                  link={books[1].link}
                  source={books[1].source}
                  category={books[1].category}
                  id={books[1].id}
                ></BookBlock>
              </AdjustibleResponsiveColumn>
              <AdjustibleResponsiveColumn
                flex={{ base: "100%", sm: "50%", md: "33%", lg: "33%" }}
                height={{
                  base: "400px",
                  sm: "550px",
                  md: "550px",
                  lg: "620px",
                }}
              >
                <BookBlock
                  image_description={books[2].image_description}
                  headline={books[2].headline}
                  link={books[2].link}
                  source={books[2].source}
                  category={books[2].category}
                  id={books[2].id}
                ></BookBlock>
              </AdjustibleResponsiveColumn>
              <AdjustibleResponsiveColumn
                flex={{ base: "100%", sm: "50%", md: "33%", lg: "33%" }}
              >
                {/* <ArchivesLink
                  color="purple"
                  text="see more BOOKS"
                  url="/books"
                  display={{
                    base: "none",
                    sm: "flex",
                    md: "none",
                  }}
                /> */}
              </AdjustibleResponsiveColumn>
            </SectionRow>
            {/* <ArchivesLink
              color="purple"
              text="see more BOOKS"
              url="/books"
              display={{ base: "flex", sm: "none", md: "flex" }}
            /> */}

            {/* alz authors podcasts row */}

            <SectionHeading heading="podcasts" bg="purple" />
            <SectionRow id="podcasts">
              <AdjustibleResponsiveColumn
                flex={{ base: "100%", sm: "49%", md: "33%", lg: "33%" }}
                height={{ small: "500px", md: "auto" }}
              >
                <PodcastBlock
                  image_description={podcasts[0].image_description}
                  headline={podcasts[0].headline}
                  link={podcasts[0].link}
                  source={podcasts[0].source}
                  category={podcasts[0].category}
                  id={podcasts[0].id}
                ></PodcastBlock>
              </AdjustibleResponsiveColumn>
              <AdjustibleResponsiveColumn
                flex={{ base: "100%", sm: "49%", md: "33%", lg: "33%" }}
                height={{ small: "500px", md: "auto" }}
              >
                <PodcastBlock
                  image_description={podcasts[1].image_description}
                  headline={podcasts[1].headline}
                  link={podcasts[1].link}
                  source={podcasts[1].source}
                  category={podcasts[1].category}
                  id={podcasts[1].id}
                ></PodcastBlock>
              </AdjustibleResponsiveColumn>
              <AdjustibleResponsiveColumn
                flex={{ base: "100%", sm: "49%", md: "33%", lg: "33%" }}
                height={{ small: "500px", md: "auto" }}
              >
                <PodcastBlock
                  image_description={podcasts[2].image_description}
                  headline={podcasts[2].headline}
                  link={podcasts[2].link}
                  source={podcasts[2].source}
                  category={podcasts[2].category}
                  id={podcasts[2].id}
                ></PodcastBlock>
              </AdjustibleResponsiveColumn>
              <AdjustibleResponsiveColumn
                flex={{ base: "100%", sm: "49%", md: "33%", lg: "33%" }}
              >
                {/* <ArchivesLink
                  color="purple"
                  text="see more PODCASTS"
                  url="/books"
                  display={{
                    base: "none",
                    sm: "flex",
                    md: "none",
                  }}
                /> */}
              </AdjustibleResponsiveColumn>
            </SectionRow>
            <Flex
              justify="end"
              align="center"
              // textTransform="uppercase"
              color="purple"
              fontWeight="600"
              fontSize="24px"
              px="2"
              textAlign="center"
            >
              <Link
                href="https://alzauthors.com"
                fontSize="18px"
                target="_blank"
              >
                see more from Alz Authors
              </Link>
              <ArrowForwardIcon h="18px" w="18px" ml="1" mt="2px" />
            </Flex>
          </Flex>
        );
      }}
    />
  );
}

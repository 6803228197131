import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Flex } from "@chakra-ui/react";
import SectionHeading from "./SectionHeading";
import SectionRow from "./SectionRow";
import ResponsiveColumn from "./ResponsiveColumn";
import ArticleBlock from "./ArticleBlock";
import ArchivesLink from "./ArchivesLink";

export default function Science() {
  return (
    <StaticQuery
      query={graphql`
        query ScienceQuery {
          allAirtable(
            filter: { table: { eq: "snapshots" } }
            sort: { fields: data___date_created, order: DESC }
            limit: 1
          ) {
            edges {
              node {
                id
                data {
                  snapshot
                  date_created
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const snapshot = JSON.parse(
          data.allAirtable.edges[0].node.data.snapshot
        );
        const science = snapshot.science;
        return (
          <Flex id="science" direction="column" mb="6">
            <SectionHeading heading="science" />
            <SectionRow id="science">
              <ResponsiveColumn
                flex={{ base: "100%", sm: "50%", md: "33%", lg: "33%" }}
              >
                <ArticleBlock
                  image_description={science[0].image_description}
                  headline={science[0].headline}
                  link={science[0].link}
                  source={science[0].source}
                  category={science[0].category}
                  id={science[0].id}
                ></ArticleBlock>
              </ResponsiveColumn>
              <ResponsiveColumn
                flex={{ base: "100%", sm: "50%", md: "33%", lg: "33%" }}
              >
                <ArticleBlock
                  image_description={science[1].image_description}
                  headline={science[1].headline}
                  link={science[1].link}
                  source={science[1].source}
                  category={science[1].category}
                  id={science[1].id}
                ></ArticleBlock>
              </ResponsiveColumn>
              <ResponsiveColumn
                flex={{ base: "100%", sm: "50%", md: "33%", lg: "33%" }}
              >
                <ArticleBlock
                  image_description={science[2].image_description}
                  headline={science[2].headline}
                  link={science[2].link}
                  source={science[2].source}
                  category={science[2].category}
                  id={science[2].id}
                ></ArticleBlock>
              </ResponsiveColumn>
              <ResponsiveColumn
                flex={{ base: "0", sm: "50%", md: "0%", lg: "0" }}
              >
                <ArchivesLink
                  text="see more SCIENCE articles"
                  url="/science"
                  display={{
                    base: "none",
                    sm: "flex",
                    md: "none",
                  }}
                />
              </ResponsiveColumn>
            </SectionRow>
            <ArchivesLink
              text="see more SCIENCE articles"
              url="/science"
              display={{ base: "flex", sm: "none", md: "flex" }}
            />
          </Flex>
        );
      }}
    />
  );
}

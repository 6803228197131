import React from "react";
import GatsbyLink from "gatsby-link";
import { Flex, Text, Image } from "@chakra-ui/react";
import slugify from "slugify";

export default function SpotlightLarge({
  image_description,
  headline,
  source,
  category,
  id,
}) {
  // const img = getImage(image);
  const slug = slugify(headline, {
    lower: true,
    strict: true,
  });
  const url = `/${category}/${slug}`;
  const local_image_path = `/images/${category}_${id}.jpg`;
  return (
    <Flex
      m="0 auto"
      h={{ base: "300px", md: "400px" }}
      w="100%"
      maxW="1168px"
      direction="column"
      fontFamily="Montserrat"
      bg="white"
      as={GatsbyLink}
      to={url}
      target="_blank"
      pos="relative"
      overflow={"none"}
    >
      <Flex h="100%" w="100%">
        <Image
          src={local_image_path}
          alt={image_description}
          width="100%"
          objectFit="cover"
        />
      </Flex>
      <Flex
        pos="absolute"
        bgGradient="linear(to-t, #000000, transparent)"
        opacity=".7"
        h="100%"
        w="100%"
      ></Flex>
      <Flex
        direction="column"
        justify="space-evenly"
        p="3"
        pos="absolute"
        bottom="0"
      >
        <Flex direction="column" py="1">
          <Text
            fontSize={{ base: "18px", sm: "20px", lg: "24px", xl: "28px" }}
            fontWeight="700"
            color="white"
          >
            {headline}
          </Text>
          <Text
            fontSize={{ base: "14px", sm: "16px", lg: "20px" }}
            fontWeight="600"
            color="white"
          >
            Source: {source}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Flex } from "@chakra-ui/react";
import SectionHeading from "./SectionHeading";
import SectionRow from "./SectionRow";
import ResponsiveColumn from "./ResponsiveColumn";
import ArticleBlock from "./ArticleBlock";
import ArchivesLink from "./ArchivesLink";

export default function Videos() {
  return (
    <StaticQuery
      query={graphql`
        query VideoQuery {
          allAirtable(
            filter: { table: { eq: "snapshots" } }
            sort: { fields: data___date_created, order: DESC }
            limit: 1
          ) {
            edges {
              node {
                id
                data {
                  snapshot
                  date_created
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const snapshot = JSON.parse(
          data.allAirtable.edges[0].node.data.snapshot
        );
        const videos = snapshot.videos;
        return (
          <Flex id="videos" direction="column" mb="6">
            <SectionHeading heading="videos" />
            <SectionRow>
              <ResponsiveColumn
                flex={{ base: "100%", sm: "50%", md: "33%", lg: "33%" }}
              >
                <ArticleBlock
                  image_description={videos[0].image_description}
                  headline={videos[0].headline}
                  link={videos[0].link}
                  source={videos[0].source}
                  category={videos[0].category}
                  id={videos[0].id}
                ></ArticleBlock>
              </ResponsiveColumn>
              <ResponsiveColumn
                flex={{ base: "100%", sm: "50%", md: "33%", lg: "33%" }}
              >
                <ArticleBlock
                  image_description={videos[1].image_description}
                  headline={videos[1].headline}
                  link={videos[1].link}
                  source={videos[1].source}
                  category={videos[1].category}
                  id={videos[1].id}
                ></ArticleBlock>
              </ResponsiveColumn>
              <ResponsiveColumn
                flex={{ base: "100%", sm: "50%", md: "33%", lg: "33%" }}
              >
                <ArticleBlock
                  image_description={videos[2].image_description}
                  headline={videos[2].headline}
                  link={videos[2].link}
                  source={videos[2].source}
                  category={videos[2].category}
                  id={videos[2].id}
                ></ArticleBlock>
              </ResponsiveColumn>
              <ResponsiveColumn
                flex={{ base: "0", sm: "50%", md: "0%", lg: "0" }}
              >
                <ArchivesLink
                  text="see more VIDEOS"
                  link="/videos"
                  display={{
                    base: "none",
                    sm: "flex",
                    md: "none",
                  }}
                />
              </ResponsiveColumn>
            </SectionRow>
            <ArchivesLink
              text="see more VIDEOS"
              url="/videos"
              display={{ base: "flex", sm: "none", md: "flex" }}
            />
          </Flex>
        );
      }}
    />
  );
}

import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Flex, Link } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export default function ArchivesLink({ text, url, display, color }) {
  return (
    <Flex
      justify="end"
      align="center"
      // textTransform="uppercase"
      color={color ? color : "green.500"}
      fontWeight="600"
      fontSize="24px"
      px="2"
      display={display}
      textAlign="center"
    >
      <Link as={GatsbyLink} to={url} fontSize="18px">
        {text}
      </Link>
      <ArrowForwardIcon h="18px" w="18px" ml="1" mt="2px" />
    </Flex>
  );
}

import React, { useState, useEffect } from "react";
import "../styles/global.css";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import { Container } from "@chakra-ui/react";
import EmailSignUpLarge from "../components/EmailSignupLarge";
import NewsletterModal from "../components/NewsletterModal";

import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/900.css";
import "@fontsource/work-sans/100.css";
import "@fontsource/work-sans/200.css";
import "@fontsource/work-sans/300.css";
import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/500.css";
import "@fontsource/work-sans/600.css";
import "@fontsource/work-sans/700.css";
import "@fontsource/work-sans/800.css";
import "@fontsource/work-sans/900.css";
import "@fontsource/cardo/400.css";
import "@fontsource/cardo/700.css";
import "@fontsource/square-peg";

import Spotlight from "../components/Spotlight";
import Videos from "../components/Videos";
import Health from "../components/Health";
import Science from "../components/Science";
import Technology from "../components/Technology";
import Perspectives from "../components/Perspectives";
import AlzAuthors from "../components/AlzAuthors";

const IndexPage = ({ location }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const lastShown = localStorage.getItem("newsletterSignupModalLastShown");
    const currentTime = new Date().getTime();
    const oneDay = 24 * 60 * 60 * 1000; //one day in milliseconds
    const scrollThreshold = 800;

    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled > scrollThreshold) {
        if (!lastShown || currentTime - lastShown > oneDay) {
          setShowModal(true);
          localStorage.setItem(
            "newsletterSignupModalLastShown",
            currentTime.toString()
          );
          // remove event listener after showing modal
          window.removeEventListener("scroll", handleScroll);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setShowModal]);

  return (
    <Layout path={location.pathname}>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>MCI and Beyond</title>
        <meta
          name="description"
          content="Curated caregiver resources for dementia and mild cognitive impairment"
        />
        <meta
          name="facebook-domain-verification"
          content="dk1dutyavoh5d4slu3aflucb6gxyok"
        />
        <link rel="canonical" href="https://mciandbeyond.org" />
      </Helmet>
      <EmailSignUpLarge loc="header" />
      <NewsletterModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
      />
      {/* MAIN SECTION */}
      <Container
        pos="relative"
        maxW="1200px"
        px={{ base: "3" }}
        py={{ base: "2" }}
      >
        <Spotlight />
        <Videos />
        <Health />
        <Science />
        <Technology />
        <Perspectives />
        <AlzAuthors />
      </Container>
    </Layout>
  );
};

export default IndexPage;

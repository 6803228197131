import React from "react";
import { Flex, Text, Link, Image } from "@chakra-ui/react";

export default function PodcastBlock({
  image,
  image_description,
  headline,
  link,
  source,
  category,
  id,
}) {
  const local_image_path = `/images/${category}_${id}.jpg`;

  return (
    <Flex
      m="0 auto"
      w="100%"
      direction="column"
      fontFamily="Montserrat"
      as={Link}
      href={link}
      target="_blank"
      color="purple"
      h={{ sm: "auto", md: "400px", lg: "450px" }}
      mb="6"
    >
      <Flex>
        <Image
          objectFit="contain"
          src={local_image_path}
          alt={image_description}
        />
      </Flex>
      <Flex direction="column" justify="space-evenly" mt="1">
        <Flex direction="column" py="3">
          <Text fontSize={{ base: "20px" }} fontWeight="700" color="purple">
            {headline}
          </Text>
          {/* <Text fontSize={{ base: "14px" }} fontWeight="600" color="purple">
            by {source}
          </Text> */}
        </Flex>
      </Flex>
    </Flex>
  );
}

import React from "react";
import { Flex, Text } from "@chakra-ui/react";

export default function SectionHeading({ heading, bg }) {
  return (
    <Flex px="1">
      <Text
        bg={bg ? bg : "green.400"}
        py="2"
        px="4"
        my="3"
        color="white"
        fontSize="24px"
        fontWeight="600"
        textTransform="uppercase"
      >
        {heading}
      </Text>
    </Flex>
  );
}

import React from "react";

import { Flex } from "@chakra-ui/react";

export default function AdjustibleResponsiveColumn({
  height,
  width,
  children,
  flex,
  id,
  bgColor,
}) {
  return (
    <Flex
      id={id ? id : null}
      h={height ? height : "100%"}
      w={width ? width : "100%"}
      p="1"
      // minW={{ base: "260px", sm: "30vw", md: "250px", lg: "180px" }}
      direction="column"
      align="center"
      justify={{ base: "center", sm: "start" }}
      // flex={flex}
      flexBasis={flex}
    >
      {children}
    </Flex>
  );
}

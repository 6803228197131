import React from "react";
import MailchimpForm from "./MailchimpForm";

import { Flex, Text } from "@chakra-ui/react";

export default function EmailSignUpLarge({ loc }) {
  return (
    <Flex
      bg="orange"
      fontFamily="Montserrat"
      justify="center"
      py="6"
      mt="4"
      minH="250px"
    >
      <Flex
        fontFamily="Montserrat"
        w="100%"
        maxW="1200px"
        align="center"
        justify="space-between"
        px="8"
        direction={{ base: "column", lg: "row" }}
      >
        <Flex
          direction={{ base: "column" }}
          textAlign="center"
          align="center"
          mt="2"
        >
          <Text
            color="white"
            fontWeight="600"
            fontSize={{ base: "24px", sm: "28px", lg: "32px" }}
            mb="2"
            // w="80%"
          >
            Sign up for our monthly newsletter!
          </Text>
          <Text
            color="white"
            fontWeight="600"
            fontSize={{ base: "16px", sm: "18px", lg: "20px" }}
            px="8"
            py="2"
            w={{ base: "100%", sm: "80%" }}
          >
            Get the latest information and inspirational stories for caregivers,
            delivered directly to your inbox.
          </Text>
        </Flex>
        <Flex px="8" pt={{ base: "2", md: "0" }} mx="2">
          <MailchimpForm loc={loc} hero={true} />
        </Flex>
      </Flex>
    </Flex>
  );
}

import React from "react";
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/react";
import MailchimpForm from "./MailchimpForm";

export default function NewsletterModal({ isOpen, closeModal }) {
  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={closeModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg="orange" py="4" px="3" m="3">
          <ModalHeader color="white">
            Sign up for our monthly newsletter!
          </ModalHeader>
          <ModalCloseButton color="white" size="xl" p="2" />
          <Flex justify="center" mb="6">
            <MailchimpForm />
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
}
